export const map_color = {
    'bad': '#F60020',
    'good': '#21983F',
    'medium': '#FEAD09',
    // 0: '#aaa', // khong tin hieu
    // 1: '#21983F', // run
    // 2: '#FEAD09', // idle
    // 3: '#F60020', // loi
    // 4: '#082b42', // off

    0: '#082b42', // off
    1: '#21983F', // run
}

export const MAP_CARD_COLOR = {
    0: '#FEAD09', 
    1: '#F60020', 
    2: '#F60020', 
    3: '#F60020', 
    4: '#F60020', 
    5: "#808080"

}

const COLOR = {
    mainColor: '#1890ff',

}

export const MapRole = {
    1: "admin",
    2: 'manager',
    3: 'employee'
}

export const ROLE = {
    SUPER_ADMIN: 1,
    AGENT: 2,
    ADMIN: 3,
    MANAGER: 4,
    EMPLOYEE: 5,
}